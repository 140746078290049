<template>
    <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
    <HelloWorld msg="Hello Vue 3.0 + Vite" />
    <ComponentWithMap />
    <!-- <router-view /> -->
    </div>
</template>
<script>
    import HelloWorld from "./components/HelloWorld.vue";
    import ComponentWithMap from './components/ComponentWithMap.vue';

    export default {
        name: "App",
        components: {
            HelloWorld,
            ComponentWithMap
        },
    };
</script>